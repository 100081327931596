import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import WidgetMockupSmallTall from '../components/WidgetMockupSmallTall';
import PartnershipGraphic from '../components/svg/PartnershipGraphic';
import WhoIsSubscribeProGraphic from '../components/svg/WhoIsSubscribeProGraphic';
import StatsSection from '../components/sections/StatsSection';

const AboutPage = () => (
  <Layout>
    <Seo title="Learn About Subscribe Pro" />

    <Header graphic={undefined}>About Subscribe Pro</Header>

    <section className="bg-sp-secondary-pastel-blue">
      <Container className="py-24">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-[55%] pr-8">
            <span className="mb-4 font-bold">Who is Subscribe Pro</span>
            <p className="text-5xl mb-7">Helping B2C brands serve their most loyal customers</p>
            <p className="text-lg">
              Hundreds of the world&apos;s top brands rely on Subscribe Pro to power their auto-ship, recurring order or
              membership programs.
            </p>
          </div>
          <div className="w-full lg:w-[45%] flex flex-col items-center mt-16 lg:mt-0">
            <WhoIsSubscribeProGraphic className="w-full" />
          </div>
        </div>
      </Container>
    </section>

    <section>
      <Container className="max-w-screen-lg">
        <div className="flex flex-col md:flex-row my-16 items-center">
          <div>
            <StaticImage
              className="object-contain block h-56 w-56 sm:h-48 sm:w-48 md:h-32 md:w-32 rounded-full xl:w-40 xl:h-40 md:mr-16 mb-14 md:mb-0"
              objectFit="contain"
              src="../images/team/gb_smile.jpeg"
              quality={95}
              alt="Garth Brantley"
              title="Garth Brantley"
            />
          </div>
          <div className="text-center md:text-left">
            <p className="text-lg font-normal leading-8 pt-6 mb-8">
              &quot;I created Subscribe Pro to help brands build outstanding re-order experiences and to nurture their loyal
              customers. Most of the e-commerce industry focuses heavily on acquiring new customers. We specialize in retaining
              your most loyal customers.&quot;
            </p>
            <p className="text-xl font-bold">Garth Brantley</p>
            <p className="font-normal">Founder and CEO</p>
          </div>
        </div>
      </Container>
    </section>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="">
            <h2 className="text-5xl font-normal">Our Story</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              Subscribe Pro was inspired by our passion for solving problems and nurturing our clients.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              Subscribe Pro was born out of a small e-commerce agency after our founder realized that many brands were struggling
              to deliver the subscription and re-order experiences their customers deserved.
            </p>

            {/* <p className="mt-4 text-lg text-sp-black font-light">
              We build customizable subscription programs that are tailored to meet the needs of online merchants, offering a
              truly consultative approach that allows us to provide the most cost-effective solutions for building sustainable
              relationships with your best customers.
            </p> */}

            <p className="mt-4 text-lg text-sp-black font-light">
              Since launching in 2014, we’ve helped hundreds of brands and retailers provide better service to their loyal
              customers by making it easy to regularly order the products they love. We focus relentlessly on keeping your
              recurring orders recurring.
            </p>
          </div>
          <div className="mt-10">
            <div className="flex justify-center items-start">
              <WidgetMockupSmallTall />
            </div>
          </div>
        </div>
        <div className="mt-24 lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="col-start-2">
            <h2 className="text-5xl font-normal">Partnership Approach</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              We believe that relationships lead to lifetime value and this core principle informs everything we do. We seek to
              build partnership style relationships with our clients and systems integrators. We align to build value over the
              long term.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              Our platform is architected and maintained to provide a solid foundation for building upon. Our support team is
              never more than a phone call away. We strive to nurture and support our clients so you can do the same for your
              loyal customers.
            </p>
          </div>
          <div className="col-start-1">
            <PartnershipGraphic className="w-full lg:m-8 mt-14" />
          </div>
        </div>
      </Container>
    </section>

    <StatsSection />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default AboutPage;
