import React from 'react';

const WhoIsSubscribeProGraphic = ({ className }) => (
  <svg className={className} width="418" height="158" viewBox="0 0 418 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M144 92.5C144 107.171 132.549 119 118.5 119C104.451 119 93 107.171 93 92.5C93 77.8292 104.451 66 118.5 66C132.549 66 144 77.8292 144 92.5Z"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <path
      opacity="0.15"
      d="M144 27.5C144 42.1708 132.549 54 118.5 54C104.451 54 93 42.1708 93 27.5C93 12.8292 104.451 1 118.5 1C132.549 1 144 12.8292 144 27.5Z"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <path
      opacity="0.15"
      d="M145 158C145 142.536 133.136 130 118.5 130C103.864 130 92 142.536 92 158"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <path
      d="M325 92.5C325 107.171 313.549 119 299.5 119C285.451 119 274 107.171 274 92.5C274 77.8292 285.451 66 299.5 66C313.549 66 325 77.8292 325 92.5Z"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <path
      opacity="0.15"
      d="M325 27.5C325 42.1708 313.549 54 299.5 54C285.451 54 274 42.1708 274 27.5C274 12.8292 285.451 1 299.5 1C313.549 1 325 12.8292 325 27.5Z"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <path
      opacity="0.15"
      d="M326 158C326 142.536 314.136 130 299.5 130C284.864 130 273 142.536 273 158"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <circle cx="28.5" cy="92.5" r="26.5" stroke="#167C5D" strokeWidth="2" />
    <circle opacity="0.15" cx="28.5" cy="27.5" r="26.5" stroke="#167C5D" strokeWidth="2" />
    <path
      opacity="0.15"
      d="M56 158C56 142.536 43.6878 130 28.5 130C13.3122 130 1 142.536 1 158"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <circle cx="208.5" cy="92.5" r="26.5" stroke="#167C5D" strokeWidth="2" />
    <circle opacity="0.15" cx="208.5" cy="27.5" r="26.5" stroke="#167C5D" strokeWidth="2" />
    <path
      opacity="0.15"
      d="M236 158C236 142.536 223.688 130 208.5 130C193.312 130 181 142.536 181 158"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <circle cx="389.5" cy="92.5" r="26.5" stroke="#167C5D" strokeWidth="2" />
    <circle opacity="0.15" cx="389.5" cy="27.5" r="26.5" stroke="#167C5D" strokeWidth="2" />
    <path
      opacity="0.15"
      d="M417 158C417 142.536 404.688 130 389.5 130C374.312 130 362 142.536 362 158"
      stroke="#167C5D"
      strokeWidth="2"
    />
    <path d="M61 92H85" stroke="#167C5D" strokeWidth="2" />
    <path d="M243 92H266" stroke="#167C5D" strokeWidth="2" />
    <path d="M152 92H175" stroke="#167C5D" strokeWidth="2" />
    <path d="M333 92H357" stroke="#167C5D" strokeWidth="2" />
  </svg>
);

export default WhoIsSubscribeProGraphic;
