import React from 'react';
import Container from '../Container';

const StatsSection = ({ className }) => (
  <section className={className}>
    <Container className="max-w-screen-xl">
      <div className="flex flex-col md:flex-row items-center content-center my-16 mb-24">
        <div className="w-full lg:w-[50%] ml-12 py-4 px-10 border-l-2 border-sp-primary-green">
          <h3 className="text-2xl text-sp-text-grey">E-commerce Brands Supported</h3>
          <p className="text-7xl md:text-8xl">250+</p>
        </div>
        <div className="w-full lg:w-[50%] mt-16 md:mt-0 ml-12 py-4 px-10 border-l-2 border-sp-primary-green">
          <h3 className="text-2xl text-sp-text-grey">Revenue From Loyal Customers</h3>
          <p className="text-7xl md:text-8xl">$2.3B+</p>
        </div>
      </div>
    </Container>
  </section>
);

export default StatsSection;
