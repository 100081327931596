import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const WidgetMockupSmallTall = ({ className }) => (
  <div className={`flex content-center items-center max-w-[384px] py-10 px-4 rounded shadow ${className}`}>
    <div className="">
      <StaticImage
        className="object-contain "
        src="../images/widget_mockup_small_tall.png"
        width={384}
        quality={95}
        objectFit="contain"
        alt="Product Details Page Subscription Widget"
      />
    </div>
  </div>
);

export default WidgetMockupSmallTall;
